const revswiper = new Swiper(".reviews__slider", {
  loop: true,

  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
  },
  slidesPerView: 1,
  spaceBetween: 20,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  // effect: "fade",
  // breakpoints: {
  //   // mobile + tablet - 320-990
  //   320: {
  //     slidesPerView: 1,
  //   },
  //   // desktop >= 991
  //   701: {
  //     slidesPerView: 2,
  //   },
  //   1001: {
  //     slidesPerView: 3,
  //   },
  // },
});

// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

// Burger Menu
function initHamburger() {
  const activeClass = "active";
  const $button = $(".burger__button");
  const $navContainer = $(".header__nav");
  const $navItems = $(".header__link");

  function toggleActiveClass() {
    [$button, $navContainer].forEach((item) => item.toggleClass(activeClass));
  }

  $button.click(toggleActiveClass);
  $navItems.click(toggleActiveClass);
}

$(document).ready(function () {
  initHamburger();
  initAnchorScrolling();
});

document
  .getElementById("contactForm")
  .addEventListener("submit", function (event) {
    event.preventDefault(); // Зупиняє відправку форми стандартним способом
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const tel = document.getElementById("tel").value;

    if (name && email && tel) {
      // Якщо форма заповнена, перенаправляє на сторінку "thanks.html"
      window.location.href = "thank.html";
    }
  });

document.addEventListener("DOMContentLoaded", function () {
  const contentBlock = document.getElementById("consent__box");
  const hideButton1 = document.getElementById("hideButton1");
  const hideButton2 = document.getElementById("hideButton2");

  // Перевіряємо, чи є інформація в localStorage про те, що блок повинен бути прихованим
  const isBlockHidden = localStorage.getItem("isBlockHidden");

  if (isBlockHidden === "true") {
    contentBlock.style.display = "none";
  }

  // Функція для приховування блоку та збереження стану в localStorage
  function hideBlock() {
    contentBlock.style.display = "none";
    localStorage.setItem("isBlockHidden", "true");
  }

  hideButton1.addEventListener("click", hideBlock);
  hideButton2.addEventListener("click", hideBlock);
});
